
// ==============================================

//        TRACKING FOR EXTERNAL LINKS

// ==============================================

$(document).ready(function() {


  //        Get Query String params
  // ==============================================

  // get query variable
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable) return pair[1];
    }
    return(false);
  }

  // get query variables
  var utm_campaign           = getQueryVariable('utm_campaign');
  var utm_source             = getQueryVariable('utm_source');
  var utm_medium             = getQueryVariable('utm_medium');
  var utm_term               = getQueryVariable('utm_term');
  var reserved_code_media    = getQueryVariable('reserved_code_media');
  var reserved_code_occasion = getQueryVariable('reserved_code_occasion');

  // get version with body dataset
  var version = document.querySelector('body').dataset.version;
  // set default value if query variable don't exist
  // if (!utm_campaign) utm_campaign = 'IFI';
  // if (!utm_source) utm_source = 'LP';
  // if (!reserved_code_occasion) {
  //   reserved_code_occasion = (version == 'gd') ? 'MAILINGIFIGDOP0420' :'MAILINGFIDIFI0420';
  // }
  // console.log('==== TRACKING ====');
  // console.log('• utm_campaign: ', utm_campaign);
  // console.log('• utm_source: ', utm_source);
  // console.log('• utm_medium: ', utm_medium);
  // console.log('• utm_term: ', utm_term);
  // console.log('• reserved_code_media: ', reserved_code_media);
  // console.log('• reserved_code_occasion: ', reserved_code_occasion);


  // add query string to href
  function setTrackingUrl(el) {
    // get base href and add ? or &
    var url = el.href;
    // !!! hack gd version
    // if (version == 'gd' && url == 'https://don.icm-institute.org/b?cid=224') url = 'https://don.icm-institute.org/b?cid=225';

    url += (url.indexOf('?') == -1) ? '?' : '&';

    // set query string array
    var qs = [];
    // if (el.dataset.utmContent)  qs.push('utm_content='+el.dataset.utmContent);
    if (utm_campaign)           qs.push('utm_campaign='+utm_campaign);
    if (utm_source)             qs.push('utm_source='+utm_source);
    if (utm_medium)             qs.push('utm_medium='+utm_medium);
    // if (utm_term)               qs.push('utm_term='+utm_term);
    // if (reserved_code_media)    qs.push('reserved_code_media='+reserved_code_media);
    // if (reserved_code_occasion) qs.push('reserved_code_occasion='+reserved_code_occasion);

    // concatenate base href and query string
    el.href = url + qs.join('&');
  }

  // set links with query params
  // document.querySelectorAll('[data-utm-content]').forEach( function( el ) {
  document.querySelectorAll('a:not(.btn-header--calculator):not(.btn-header--calendar):not(.no-tracking)').forEach( function( el ) {
    setTrackingUrl(el);
    // console.log('• href', el.href);
  });





});
